// app/routes/_index.tsx
import type { LoaderFunctionArgs } from "@remix-run/node";
import { Form, json, useActionData, useLoaderData } from "@remix-run/react";

import { useLayoutEffect } from "react";
import * as THREE from "three";
import $ from "jquery";
// import { createNoise3D } from "simplex-noise";
const SPEED = 13;
const SPIKES = 0.6;
const PROCESSING = 1;
export default () => {
  return (
    <div className="flex min-h-[100vh] ">
      <div className="flex-1 max-w-[400px]">
        {" "}
        <div className="text-slate-400 justify-between flex flex-col p-4 h-full ">
          <h1 className=" bold text-[22vw] sm:text-9xl mb-4 ">
            <span className="block leading-snug ">Private</span>
            <span className="block leading-snug opacity-60">&</span>
            <span className="block leading-snug">Safe</span>
            <span className="block leading-snug opacity-60">AI</span>
            <span className="block leading-snug"></span>
          </h1>
          <div>
            <p className="max-w-[400px] text-xl mb-8">
              Harness AI power without compromising data privacy. Keep full
              control of sensitive research data with privately hosted
              open-source models on your own servers.
            </p>
          </div>
          <p className="max-w-[400px] mt-auto text-[#d4c3cd]">
            Message us to pilot@torgon.io to join our upcoming pilot program.
          </p>
        </div>
      </div>

      <div className="blobwrapper">
        <div className="blobCircle"></div>
        <div id="blob">
          <canvas></canvas>
        </div>
      </div>
    </div>
  );
};
